// ThankYouPage.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import '../css/PrivacyPolicyPage.css'; // Import the CSS file for custom styles
console.log('Start of ThankYouPage');

const ThankYouPage = () => {
  const { t } = useTranslation(); // Single useTranslation hook
  const location = useLocation();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [demoType, setDemoType] = useState('');

  const bannerImageFile = t('thankYouPage.bannerImage');

  // Dynamically require the image so Webpack can process it
  let bannerImage;
  try {
    bannerImage = require(`../assets/images/${bannerImageFile}`);
  } catch (error) {
    console.error("Image not found:", error);
  }

  useEffect(() => {
    console.log('TYPage Query Params:', location.search);
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    console.log('TYPage Token:', token);

    const validateToken = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/validate-token?token=${token}`);
        const data = await response.json();

        if (response.ok && data.isValid) {
          setIsValid(true);
          setDemoType(data.demoType); // Get demoType from validation response
        } else {
          navigate('/'); // Invalid token, redirect to home
        }
      } catch (error) {
        console.error('Error validating token:', error);
        navigate('/'); // On error, redirect to home
      }
    };

    if (token) {
      console.log('TYPage Token Yes');
      validateToken();
    } else {
      console.log('TYPage Token No');
      navigate('/');
    }
  }, [location, navigate]);

  if (!isValid) return null;  // Render nothing if the token is not valid

  return (
    <div className="privacy-policy-page">
      <img src={bannerImage} alt="Top Banner" className="img-fluid" />
      <div className="privacy-content">
        <p>{t('thankYouPage.registrationSuccess')}</p>

        {demoType === 'callback' && (
          <p>{t('thankYouPage.registrationCallBack')}</p>
        )}

        {demoType === 'self-guided' && (
          <p>{t('thankYouPage.registrationSelfGuided')}</p>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
