import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="navbar navbar-expand-lg" style={{ backgroundColor: '#1520bf' }}>
      <div className="container-fluid">
        <div className="container-lg">
          <div className="row w-100">
            <div className="col-12 text-center"> {/* Ensure text is centered on all screen sizes */}
              <ul className="navbar-nav d-inline-flex"> {/* Keep the items inline even on small screens */}
                <li className="nav-item">
                  <Link className="nav-link text-white" to="/contact-us">{t('footer.contactUs')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-white" to="/announcements">{t('footer.announcements')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-white" to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-white" to="/terms-of-service">{t('footer.termsOfService')}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
