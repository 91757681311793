import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n'; // Initialize i18n
// import ReactGA from 'react-ga4'; // Corrected import
import { FlashMessageProvider } from './components/FlashMessageContext'; // Import the FlashMessageProvider
console.log('Start of index.js');

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <FlashMessageProvider> {/* Wrap App in FlashMessageProvider */}
      <App />
   </FlashMessageProvider>
  </React.StrictMode>
);

reportWebVitals();