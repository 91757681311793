// ContactUsPage.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import QuestionCommentForm from '../components/QuestionCommentForm'; 
import '../css/PrivacyPolicyPage.css'; // Import the CSS file for custom styles
console.log('Start of ContactUsPage.js');



const ContactUsPage = () => {
  const { t } = useTranslation();
  const bannerImageFile = t('contactUsPage.bannerImage');

  // Dynamically require the image so Webpack can process it
  let bannerImage;
  try {
    bannerImage = require(`../assets/images/${bannerImageFile}`);
  } catch (error) {
    console.error("Image not found:", error);
  }
  
  return (
    <div className="privacy-policy-page">
      {/* Full-width top image */}
      <img src={bannerImage} alt="Top Banner" className="img-fluid" />
      <legend className="border-bottom mb-4">{t('contactUsPage.legend')}</legend>
      <div className="privacy-content">
        <QuestionCommentForm /> {/* Include the QuestionCommentForm component */}
      </div>
    </div>
  );
};
export default ContactUsPage;