// DetailsPage.js
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import flexiblePlatform from '../assets/images/flexiblePlatformSide.webp'; 
import boxesWithModules from '../assets/images/boxesWithModules.webp';
import securityLock from '../assets/images/securityLock.webp';
import integrationWithOtherSystems from '../assets/images/integrationOtherSystems.webp';
import '../css/DetailsPage.css';

const DetailsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  useEffect(() => {
    const hash = location.hash;
    if (hash === '#section1' && section1Ref.current) {
      section1Ref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (hash === '#section2' && section2Ref.current) {
      section2Ref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (hash === '#section3' && section3Ref.current) {
      section3Ref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (hash === '#section4' && section4Ref.current) {
      section4Ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="details-container">
      <div className="row  border p-3 mt-3" ref={section1Ref} id="section1">
        <div className="col-lg-6">
          <img src={flexiblePlatform} className="img-fluid" alt={t('detailsPage.section1Title')} />
        </div>
        <div className="col-lg-6">
          <div className="details-text">
            <h2>{t('detailsPage.section1Title')}</h2>
            <p>{t('detailsPage.section1Text')}</p>
          </div>
        </div>
      </div>

      <div className="row  border p-3 mt-3" ref={section2Ref} id="section2">
        <div className="col-lg-6 order-lg-2">
          <img src={boxesWithModules} className="img-fluid" alt={t('detailsPage.section2Title')} />
        </div>
        <div className="col-lg-6 order-lg-1">
          <div className="details-text">
            <h2>{t('detailsPage.section2Title')}</h2>
            <p>{t('detailsPage.section2Text')}</p>
          </div>
        </div>
      </div>

      <div className="row  border p-3 mt-3" ref={section3Ref} id="section3">
        <div className="col-lg-6">
          <img src={securityLock} className="img-fluid" alt={t('detailsPage.section3Title')} />
        </div>
        <div className="col-lg-6">
          <div className="details-text">
            <h2>{t('detailsPage.section3Title')}</h2>
            <p>{t('detailsPage.section3Text')}</p>
          </div>
        </div>
      </div>

      <div className="row  border p-3 mt-3" ref={section4Ref} id="section4">
        <div className="col-lg-6 order-lg-2">
          <img src={integrationWithOtherSystems} className="img-fluid" alt={t('detailsPage.section4Title')} />
        </div>
        <div className="col-lg-6 order-lg-1">
          <div className="details-text">
            <h2>{t('detailsPage.section4Title')}</h2>
            <p>{t('detailsPage.section4Text')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPage;
