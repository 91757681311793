import React from 'react';
import { useTranslation } from 'react-i18next';
import crmImage from '../assets/images/crmDetail.webp';
import salesImage from '../assets/images/salesDetail.webp';
import warehouseDetailImage from '../assets/images/warehouseManagementDetail.webp';
import procurementImage from '../assets/images/procurementDetail.webp';
import inventoryImage from '../assets/images/inventoryManagementDetail.webp';
import productionDetailImage from '../assets/images/productionTwoDetail.webp';
import assetManagementDetailImage from '../assets/images/assetManagementDetail.webp';
import accountingFinanceImage from '../assets/images/accountingFinance.webp';
import salaryPayrollImage from '../assets/images/salaryPayroll.webp';
import timeManagementImage from '../assets/images/timeManagement.webp';
import '../css/ModulesPage.css';
import { Link } from 'react-router-dom';

const ModulesPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <legend className="border-bottom mb-4">{t('modulesPage.legend')}</legend>
      {/* First Row */}
      <div className="row">
        <div className="col-md-6">
          <div className="equal-height-box">
            <h3>{t('modulesPage.crmTitle')}</h3>
            <p>{t('modulesPage.crmText')}</p>
            <img src={crmImage} className="img-fluid" alt="CRM" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="equal-height-box">
            <h3>{t('modulesPage.salesTitle')}</h3>
            <p>{t('modulesPage.salesText')}</p>
            <img src={salesImage} className="img-fluid" alt="Sales" />
          </div>
        </div>
      </div>

      {/* Second Row */}
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="equal-height-box">
            <h3>{t('modulesPage.warehouseManagementTitle')}</h3>
            <p>{t('modulesPage.warehouseManagementText')}</p>
            <img src={warehouseDetailImage} className="img-fluid" alt="Warehouse Management" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="equal-height-box">
            <h3>{t('modulesPage.procurementTitle')}</h3>
            <p>{t('modulesPage.procurementText')}</p>
            <img src={procurementImage} className="img-fluid" alt="Procurement" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="equal-height-box">
            <h3>{t('modulesPage.inventoryManagementTitle')}</h3>
            <p>{t('modulesPage.inventoryManagementText')}</p>
            <img src={inventoryImage} className="img-fluid" alt="Inventory Management" />
          </div>
        </div>
      </div>

      <div className="text-center mt-4">
        <h3>{t('homePage.joinRevolution')}</h3>
        <Link to="/register" className="btn btn-primary ms-3 mt-1" style={{ backgroundColor: '#1520bf', borderColor: '#1520bf' }}>
          {t('layout.buttons.requestDemo')}
        </Link>
      </div>

      {/* Third Row */}
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="equal-height-box d-flex flex-column flex-md-row">
            {/* Text Container with Right Padding on Medium and Larger Screens */}
            <div className="col-md-6 pe-md-4">
              <h3>{t('modulesPage.productionTitle')}</h3>
              <p>{t('modulesPage.productionText')}</p>
            </div>
            
            {/* Image Container without Left Margin */}
            <div className="col-md-6">
              <img src={productionDetailImage} className="img-fluid" alt="Production" />
            </div>
          </div>
        </div>
      </div>


      {/* Fourth Row */}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="equal-height-box d-flex flex-column justify-content-between">
            <div>
              <h3>{t('modulesPage.accountingFinanceTitle')}</h3>
              <p>{t('modulesPage.accountingFinanceText')}</p>
            </div>
            <div>
              <img src={accountingFinanceImage} className="img-fluid" alt="Accounting and Finance" />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <div className="equal-height-box">
                <h3>{t('modulesPage.assetManagementTitle')}</h3>
                <p>{t('modulesPage.assetManagementText')}</p>
                <img src={assetManagementDetailImage} className="img-fluid" alt="Asset Management" />
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="equal-height-box">
                    <h4>{t('modulesPage.salaryPayrollTitle')}</h4>
                    <p>{t('modulesPage.salaryPayrollText')}</p>
                    <img src={salaryPayrollImage} className="img-fluid" alt="Salary and Payroll" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="equal-height-box">
                    <h4>{t('modulesPage.timeManagementTitle')}</h4>
                    <p>{t('modulesPage.timeManagementText')}</p>
                    <img src={timeManagementImage} className="img-fluid" alt="Time Management" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <h3>{t('general.requestDemo')}</h3>
        <Link to="/register" className="btn btn-primary ms-3 mt-1 mb-4" style={{ backgroundColor: '#1520bf', borderColor: '#1520bf' }}>
          {t('layout.buttons.requestDemo')}
        </Link>
      </div>
    </div>
  );
};

export default ModulesPage;
