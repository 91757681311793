//  RegisterForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useFlashMessage } from './FlashMessageContext';
import useForm from '../hooks/useForm';
import FlashMessage from './FlashMessage';
import { useNavigate } from 'react-router-dom';
console.log('Start of RegisterForm.js');

const RegisterForm = () => {
    const { setFlashMessage, clearFlashMessage } = useFlashMessage();
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [demoType, setDemoType] = useState(''); // New state for demo type
    const navigate = useNavigate();

    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        confirm_email: '',
        phone_number: '',
        company_name: ''
    };

    const validatePhoneNumber = useCallback((number) => {
        const phoneNumber = parsePhoneNumberFromString(number, 'US');
        return phoneNumber ? phoneNumber.isValid() : false;
    }, []);

    const validate = useCallback((values) => {
        let errors = {};
        if (!values.first_name.trim()) errors.first_name = t('registerForm.messages.firstNameRequired');
        if (!values.last_name.trim()) errors.last_name = t('registerForm.messages.lastNameRequired');
        if (!/\S+@\S+\.\S+/.test(values.email)) errors.email = t('registerForm.messages.emailAddressRequired');
        if (values.email !== values.confirm_email) errors.confirm_email = t('registerForm.messages.emailsDoNotMatch');
        if (!/\S+@\S+\.\S+/.test(values.confirm_email)) errors.confirm_email = t('registerForm.messages.confirmEmailAddressRequired');
        if (!validatePhoneNumber(values.phone_number)) errors.phone_number = t('registerForm.messages.phoneNumberRequired');
        if (!values.company_name.trim()) errors.company_name = t('registerForm.messages.companyNameRequired');
        return errors;
    }, [t, validatePhoneNumber]);

    const [
        formData,
        formErrors,
        handleChange,
        handleSubmit,
        resetForm,
        isDirty,
        handleFocus
    ] = useForm(initialValues, validate, clearFlashMessage);
    
    // Verify that handleSubmit is a function
    useEffect(() => {
        if (typeof handleSubmit !== 'function') {
            console.error('handleSubmit is not a function:', handleSubmit);
        }
    }, [handleSubmit]);

    useEffect(() => {
      const script = document.createElement('script');
      let siteKey;
      if (process.env.REACT_APP_NODE_ENV === 'production') {
          siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_PRODUCTION;
      } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
          siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_STAGING;
          console.log('reCAPTCHA siteKey for staging:', siteKey);
      } else {
          siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEVELOPMENT;
      }
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      return () => {
          document.body.removeChild(script);
      };
    }, []);

    const onSubmit = useCallback(async (e, demoType) => {
        e.preventDefault();
        console.log('Form submission with reCAPTCHA', demoType);
        if (window.grecaptcha) {
            window.grecaptcha.ready(async () => {
                let siteKey;
                if (process.env.REACT_APP_NODE_ENV === 'production') {
                    siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_PRODUCTION;
                } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
                    siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_STAGING;
                    console.log('reCAPTCHA siteKey for staging:', siteKey);
                } else {
                    siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEVELOPMENT;
                }

                const token = await window.grecaptcha.execute(siteKey, { action: 'submit' });
                console.log('reCAPTCHA token:', token);

                const dataWithToken = {
                    ...formData,
                    recaptchaToken: token,
                    demoType: demoType, // Add demoType to the form data
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(dataWithToken),
                    });
                    const data = await response.json();
                    console.log('Form submitted successfully:', data);
                    if (data.status === "success") {
                        console.log('demoType:', demoType);
                        if (demoType === "callback") {
                            resetForm();
                            const token = data.token;  // Get the token from the backend response
                            console.log('RegForm Token: ', token)
                            // Redirect with token as query parameter
                            navigate(`/thank-you?token=${token}&type=${demoType}`);
                        } else{
                            setMessage(t(data.message));
                            setMessageType('success');
                            resetForm();
                        }
                    } else {
                        setMessage(t(data.message));
                        setMessageType('warning');
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                    setMessage(t('registerForm.messages.registrationFailed'));
                    setMessageType('error');
                }
            });
        } else {
            console.error('reCAPTCHA not loaded.');
            setMessage(t('general.recaptchaNotLoaded'));
            setMessageType('error');
        }
    }, [formData, setMessage, setMessageType, t, resetForm, navigate]);

    const handleInputChange = (e) => {
        // console.log('Input change');
        handleChange(e);
        clearFlashMessage();
        setMessage('');
        setMessageType('');
    };

    const handleInputFocus = (e) => {
        handleFocus(e);
        // console.log('Input focused');
    };

    const handleDemoTypeSubmit = useCallback((e, type) => {
        e.preventDefault();
        console.log('handleDemoTypeSubmit', type);
        
        // Ensure all required data is available before submission
        if (formData && Object.keys(formData).length > 0) {
            handleSubmit(e, () => {
                onSubmit(e, type);  // Pass the type directly to onSubmit
            });
        } else {
            console.error('Form data is not ready for submission');
        }
    }, [formData, handleSubmit, onSubmit]);
  

    return (
        <div>
            {message && <FlashMessage message={message} type={messageType} />}
            <form onSubmit={(e) => handleSubmit(e, onSubmit)} noValidate className="needs-validation">
                <div className="form-group">
                    <label htmlFor="first_name">{t('registerForm.labels.firstName')}</label>
                    <input
                        name="first_name"
                        type="text"
                        className={`form-control ${isDirty.first_name && formErrors.first_name ? 'is-invalid' : ''}`}
                        id="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        autoComplete='given-name'
                    />
                    {isDirty.first_name && formErrors.first_name && (
                        <div className="invalid-feedback">
                            {formErrors.first_name}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="last_name">{t('registerForm.labels.lastName')}</label>
                    <input
                        name="last_name"
                        type="text"
                        className={`form-control ${isDirty.last_name && formErrors.last_name ? 'is-invalid' : ''}`}
                        id="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        autoComplete='family-name'
                    />
                    {isDirty.last_name && formErrors.last_name && (
                        <div className="invalid-feedback">
                            {formErrors.last_name}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="email">{t('registerForm.labels.email')}</label>
                    <input
                        name="email"
                        type="email"
                        className={`form-control ${isDirty.email && formErrors.email ? 'is-invalid' : ''}`}
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        autoComplete='email'
                    />
                    {isDirty.email && formErrors.email && (
                        <div className="invalid-feedback">
                            {formErrors.email}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="confirm_email">{t('registerForm.labels.confirmEmail')}</label>
                    <input
                        name="confirm_email"
                        type="confirm_email"
                        className={`form-control ${isDirty.confirm_email && formErrors.confirm_email ? 'is-invalid' : ''}`}
                        id="confirm_email"
                        value={formData.confirm_email}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        autoComplete='email'
                    />
                    {isDirty.confirm_email && formErrors.confirm_email && (
                        <div className="invalid-feedback">
                            {formErrors.confirm_email}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="phone_number">{t('registerForm.labels.phoneNumber')}</label>
                    <input
                        name="phone_number"
                        type="text"
                        className={`form-control ${isDirty.phone_number && formErrors.phone_number ? 'is-invalid' : ''}`}
                        id="phone_number"
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        autoComplete='tel'
                    />
                    {isDirty.phone_number && formErrors.phone_number && (
                        <div className="invalid-feedback">
                            {formErrors.phone_number}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="company_name">{t('registerForm.labels.companyName')}</label>
                    <input
                        name="company_name"
                        type="company_name"
                        className={`form-control ${isDirty.company_name && formErrors.company_name ? 'is-invalid' : ''}`}
                        id="company_name"
                        value={formData.company_name}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    {isDirty.company_name && formErrors.company_name && (
                        <div className="invalid-feedback">
                            {formErrors.company_name}
                        </div>
                    )}
                </div>
                <button 
                    type="button" 
                    className="btn btn-primary mt-4 mb-2 me-3" 
                    onClick={(e) => handleDemoTypeSubmit(e, 'callback')}
                >
                    {t('registerForm.buttons.scheduleCallback')}
                </button>
                <button 
                    type="button" 
                    className="btn btn-secondary mt-4 mb-2" 
                    onClick={(e) => handleDemoTypeSubmit(e, 'self-guided')}
                >
                    {t('registerForm.buttons.spinUpDemo')}
                </button>
            </form>
        </div>
    );
};

export default RegisterForm;
