import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FlashMessage from './FlashMessage';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import c3visionLogo from '../assets/images/c3vision_logo_no_bg.png';
import { useTranslation } from 'react-i18next';
import '../css/Layout.css'; // We'll create this file for custom styles
import { Menu, Home, Info, BookOpen } from 'lucide-react'; // Import icons

const Layout = ({ children }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    setIsNavCollapsed(true);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <meta name="description" content="" />
        <meta name="author" content="The c3vision Dev Team" />
        <meta name="generator" content="c3vision" />
      </Helmet>

      <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container">
          <button className="navbar-toggler d-lg-none" type="button" onClick={handleNavCollapse}>
            <Menu size={24} /> {/* Use Menu icon for hamburger */}
          </button>
          <Link to="/" className="navbar-brand">
            <img src={c3visionLogo} height="50" alt="c3vision" />
          </Link>
          <div className={`navbar-collapse ${isNavCollapsed ? 'collapse' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link 
                  className={`nav-link ${location.pathname === '/modules' ? 'active fw-bold' : ''}`} 
                  to="/modules"
                >
                  <BookOpen size={18} className="me-1" /> {/* Add icon next to text */}
                  {t('layout.modules')}
                </Link>
              </li>
              <li className="nav-item">
                <Link 
                  className={`nav-link ${location.pathname === '/about' ? 'active fw-bold' : ''}`} 
                  to="/about"
                >
                  <Info size={18} className="me-1" /> {/* Add icon next to text */}
                  {t('layout.about')}
                </Link>
              </li>
            </ul>
          </div>
          <Link to="/register" className="btn btn-primary ms-3 custom-btn">
            {t('layout.buttons.requestDemo')}
          </Link>
        </div>
      </nav>

      <main role="main" className="container">
        <FlashMessage />
        <div className="row">
          {children}
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Layout;