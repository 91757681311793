import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/PrivacyPolicyPage.css'; // Import the CSS file for custom styles
console.log('Start of PrivacyPolicyPage.js');

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const bannerImageFile = t('privacyPolicyPage.bannerImage');

  // Dynamically require the image so Webpack can process it
  let bannerImage;
  try {
    bannerImage = require(`../assets/images/${bannerImageFile}`);
  } catch (error) {
    console.error("Image not found:", error);
  }

  return (
    <div className="privacy-policy-page">
        {/* Full-width top image */}
        <img src={bannerImage} alt="Top Banner" className="img-fluid" />
        <legend className="border-bottom mb-4">{t('privacyPolicyPage.legend')}</legend>
        <div className="privacy-content">
            <h3>{t('privacyPolicyPage.effectiveDate')}</h3>

            <h4>{t('privacyPolicyPage.section1Title')}</h4>
            <p className="indent-paragraph">{t('privacyPolicyPage.section1Text')}</p>
            <ul className="no-space-list indent-list">
                <li>{t('privacyPolicyPage.section1Text1')}</li>
                <li>{t('privacyPolicyPage.section1Text2')}</li>
                <li>{t('privacyPolicyPage.section1Text3')}</li>
                <ul className="second-indent-list indent-list">
                    <li>{t('privacyPolicyPage.section1Text4')}</li>
                    <li>{t('privacyPolicyPage.section1Text5')}</li>
                    <li>{t('privacyPolicyPage.section1Text6')}</li>
                    <li>{t('privacyPolicyPage.section1Text7')}</li>
                </ul>
            </ul>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section1Text8')}</p>

            <h4>{t('privacyPolicyPage.section2Title')}</h4>
            <p className="indent-paragraph">{t('privacyPolicyPage.section2Text')}</p>
            <ul className="no-space-list indent-list">
                <li>{t('privacyPolicyPage.section2Text1')}</li>
                <li>{t('privacyPolicyPage.section2Text2')}</li>
                <li>{t('privacyPolicyPage.section2Text3')}</li>
            </ul>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section2Text4')}</p>    

            <h4>{t('privacyPolicyPage.section3Title')}</h4>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section3Text')}</p>

            <h4>{t('privacyPolicyPage.section4Title')}</h4>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section4Text')}</p>

            <h4>{t('privacyPolicyPage.section5Title')}</h4>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section5Text')}</p>

            <h4>{t('privacyPolicyPage.section6Title')}</h4>
            <p className="indent-paragraph">{t('privacyPolicyPage.section6Text')}</p>
            <ul className="no-space-list indent-list">
                <li>{t('privacyPolicyPage.section6Text1')}</li>
                <li>{t('privacyPolicyPage.section6Text2')}</li>
                <li>{t('privacyPolicyPage.section6Text3')}</li>
            </ul>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section6Text4')}</p>    

            <h4>{t('privacyPolicyPage.section7Title')}</h4>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section7Text')}</p>

            <h4>{t('privacyPolicyPage.section8Title')}</h4>
            <p className="indent-paragraph mb-2">{t('privacyPolicyPage.section8Text')}</p>
        </div>
    </div>
  );
};

export default PrivacyPolicyPage;
