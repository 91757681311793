// ConfirmPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FlashMessage from '../components/FlashMessage';
import { useNavigate } from 'react-router-dom';


const ConfirmPage = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const navigate = useNavigate();

  const bannerImageFile = t('confirmPage.bannerImage');

  // Dynamically require the image so Webpack can process it
  let bannerImage;
  try {
    bannerImage = require(`../assets/images/${bannerImageFile}`);
  } catch (error) {
    console.error("Image not found:", error);
  }

  useEffect(() => {
    console.log('CFP useEffect');
    
    const confirmEmail = async (token) => {
      console.log('CFP useEffect ConfirmEmail');
      try {
        console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
        setMessage(t('confirmPage.messages.validatingLink'));
        setMessageType('warning');
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/confirm/${token}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.log('CFP response NOT OK');
          throw new Error(t(errorData.error) || t('general.somethingWentWrong'));
        }
  
        const data = await response.json();
        console.log('CFP data:', data);
        console.log('CFP data.message:', data.message);
        console.log('CFP data.messageType:', data.messageType);
        if (data.messageType === "success") {
          navigate(`/thank-you?token=${token}&type=${data.demoType}`);
        };
  
        setMessage(t(data.message));
        setMessageType(data.messageType);
      } catch (error) {
        console.error('CFP Fetch error:', error);
        setMessage(t(error.message));
        setMessageType('error');
      }
    };
  
    confirmEmail(token);
  }, [token, t, navigate]); // Add 'navigate' to the dependency array
  

  return (
    <div className="privacy-policy-page">
      <img src={bannerImage} alt="Top Banner" className="img-fluid" />
      <div className="privacy-content">
        {message && <FlashMessage message={message} type={messageType} />}
      </div>
    </div>
  );
};

export default ConfirmPage;

