//  QuestionCommenmtForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useFlashMessage } from './FlashMessageContext';
import useForm from '../hooks/useForm';
import FlashMessage from './FlashMessage';
console.log('Start of QuestionCommentForm.js');

const QuestionCommentForm = () => {
    const { setFlashMessage, clearFlashMessage } = useFlashMessage();
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        question_comment: ''
    };
    
    const validatePhoneNumber = useCallback((number) => {
        const phoneNumber = parsePhoneNumberFromString(number, 'US');
        return phoneNumber ? phoneNumber.isValid() : false;
    }, []);

    const validate = useCallback((values) => {
        let errors = {};
        if (!values.first_name.trim()) errors.first_name = t('questionCommentForm.messages.firstNameRequired');
        if (!values.last_name.trim()) errors.last_name = t('questionCommentForm.messages.lastNameRequired');
        if (!/\S+@\S+\.\S+/.test(values.email)) errors.email = t('questionCommentForm.messages.emailAddressRequired');
        if (!validatePhoneNumber(values.phone_number)) errors.phone_number = t('questionCommentForm.messages.phoneNumberRequired');
        if (!values.question_comment.trim()) errors.question_comment = t('questionCommentForm.messages.questionCommentRequired');
        return errors;
    }, [t, validatePhoneNumber]);

    const [formData, formErrors, handleChange, handleSubmit, resetForm, isDirty, handleFocus] = useForm(initialValues, validate, clearFlashMessage);

    useEffect(() => {
        const script = document.createElement('script');
        let siteKey;
        if (process.env.REACT_APP_NODE_ENV === 'production') {
            siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_PRODUCTION;
        } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
            siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_STAGING;
            console.log('QCF handleSubmit reCAPTCHA siteKey for staging:', process.env.REACT_APP_RECAPTCHA_SITE_KEY_STAGING);
        } else {
            siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEVELOPMENT;
        }
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onSubmit = async () => {
        console.log('QCF handleSubmit start');
        if (window.grecaptcha) {
            window.grecaptcha.ready(async () => {
                let siteKey;
                if (process.env.REACT_APP_NODE_ENV === 'production') {
                    siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_PRODUCTION;
                } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
                    siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_STAGING;
                } else {
                    siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEVELOPMENT;
                }

                const token = await window.grecaptcha.execute(siteKey, { action: 'submit' });

                const dataWithToken = {
                    ...formData,
                    recaptchaToken: token,
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/send_question_comment`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(dataWithToken),
                    });
                    const data = await response.json();
                    // console.log('QCF handleSubmit Form submitted successfully:', data);

                    if (data.status === "success") {
                        setMessage(t(data.message));
                        setMessageType('success');
                        resetForm();  // Use the new resetForm function instead of setFormData
                    } else {
                        setMessage(t(data.message));
                        setMessageType('warning');
                    }
                } catch (error) {
                    // console.error('QCF handleSubmit Error submitting form:', error);
                    setMessage(t('questionCommentForm.messages.sendQuestionCommentFailed'));
                    setMessageType('error');
                }
            });
        } else {
            // console.error('QCF handleSubmit Error: reCAPTCHA not loaded.');
            setMessage(t('general.recaptchaNotLoaded'));
            setMessageType('error');
        }
    };

    const handleInputChange = (e) => {
        console.log('QCF handleInputChange');
        setMessage('');
        handleChange(e);
        clearFlashMessage();
        setMessage('');
        setMessageType('');
    };

    const handleInputFocus = (e) => {
        handleFocus(e);
        console.log('QCF handleInputFocus');
    };

    return (
        <div>
            {message && <FlashMessage message={message} type={messageType} />}
            <form onSubmit={(e) => handleSubmit(e, onSubmit)} noValidate className="needs-validation">
                <div className="form-group">
                    <label htmlFor="first_name">{t('questionCommentForm.labels.firstName')}</label>
                    <input
                        name="first_name"
                        type="text"
                        className={`form-control ${isDirty.first_name && formErrors.first_name ? 'is-invalid' : ''}`}
                        id="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    {isDirty.first_name && formErrors.first_name && (
                        <div className="invalid-feedback">
                            {formErrors.first_name}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="last_name">{t('questionCommentForm.labels.lastName')}</label>
                    <input
                        name="last_name"
                        type="text"
                        className={`form-control ${isDirty.last_name && formErrors.last_name ? 'is-invalid' : ''}`}
                        id="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    {isDirty.last_name && formErrors.last_name && (
                        <div className="invalid-feedback">
                            {formErrors.last_name}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="email">{t('questionCommentForm.labels.email')}</label>
                    <input
                        name="email"
                        type="email"
                        className={`form-control ${isDirty.email && formErrors.email ? 'is-invalid' : ''}`}
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    {isDirty.email && formErrors.email && (
                        <div className="invalid-feedback">
                            {formErrors.email}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="phone_number">{t('questionCommentForm.labels.phoneNumber')}</label>
                    <input
                        name="phone_number"
                        type="text"
                        className={`form-control ${isDirty.phone_number && formErrors.phone_number ? 'is-invalid' : ''}`}
                        id="phone_number"
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    {isDirty.phone_number && formErrors.phone_number && (
                        <div className="invalid-feedback">
                            {formErrors.phone_number}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="question_comment">{t('questionCommentForm.labels.questionComment')}</label>
                    <input
                        name="question_comment"
                        type="text"
                        className={`form-control ${isDirty.question_comment && formErrors.question_comment ? 'is-invalid' : ''}`}
                        id="question_comment"
                        value={formData.question_comment}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    {isDirty.question_comment && formErrors.question_comment && (
                        <div className="invalid-feedback">
                            {formErrors.question_comment}
                        </div>
                    )}
                </div>
                <button type="submit" className="btn btn-primary mt-2 mb-2">{t('questionCommentForm.buttons.submit')}</button>
            </form>
        </div>
    );
};

export default QuestionCommentForm;
