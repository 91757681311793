// FlashMessageContext.js
import React, { createContext, useState, useContext } from 'react';
console.log('Start of FlashMessageContext.js');

const FlashMessageContext = createContext({
  message: null, // Default to null or an empty object, as preferred
  setFlashMessage: () => {}, // No-op function as a placeholder
  clearFlashMessage: () => {}, // No-op function as a placeholder
});

export const FlashMessageProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  // Set a flash message with a type ('success', 'error', 'info', 'warning')
  const setFlashMessage = (msg, type = 'info') => {
    setMessage({ text: msg, type });
  };

  // Clear the current flash message
  const clearFlashMessage = () => setMessage(null);

  return (
    <FlashMessageContext.Provider value={{ message, setFlashMessage, clearFlashMessage }}>
      {children}
    </FlashMessageContext.Provider>
  );
};

// Custom hook to use the flash message context
export const useFlashMessage = () => useContext(FlashMessageContext);
