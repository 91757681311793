// RegisterPage.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegisterForm from '../components/RegisterForm'; 
import c3v60Logo from '../assets/images/c3v60_logo.jpg';
import '../css/PrivacyPolicyPage.css'; // Import the CSS file for custom styles
console.log('Start of RegisterPage.js');

const RegisterPage = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormError = (message) => {
    setErrorMessage(message);
  };
  const bannerImageFile = t('registerPage.bannerImage');
  // Dynamically require the image so Webpack can process it
  let bannerImage;
  try {
    bannerImage = require(`../assets/images/${bannerImageFile}`);
  } catch (error) {
    console.error("Image not found:", error);
  }

  return (
    <div className="privacy-policy-page">
      {/* Full-width top image */}
      <img src={bannerImage} alt="Top Banner" className="img-fluid" />
      <legend className="border-bottom mb-4">{t('registerPage.legend1')}<img src={c3v60Logo} height="50" alt="c3v60" /></legend>

      <div>
        {/* Explanation of Demo Options */}
        <h5>{t('registerPage.scheduleDemo')}</h5>
        <p>{t('registerPage.scheduleDemoText')}</p>

        <h5>{t('registerPage.howToRegister')}</h5>
        <p>{t('registerPage.howToRegisterText1')}</p>

        <legend className="border-bottom mb-4">{t('registerPage.legend3')}</legend>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

        {/* Include the RegisterForm component */}
        <RegisterForm onError={handleFormError} /> 

        <p>{t('registerPage.howToRegisterText2')}</p>

        <h5>{t('registerPage.whyRegister')}</h5>
        <p><b>{t('registerPage.tailoredNeedsText1')}</b>{t('registerPage.tailoredNeedsText2')}</p>
        <p><b>{t('registerPage.expertInsightText1')}</b>{t('registerPage.expertInsightText2')}</p>
        <p><b>{t('registerPage.selfGuidedDemoText1')}</b>{t('registerPage.selfGuidedDemoText2')}</p>
        <p><b>{t('registerPage.seeActionText1')}</b>{t('registerPage.seeActionText2')}</p>

      </div>
    </div>
  );
};

export default RegisterPage;

