import { useEffect } from 'react';

const DynamicScriptLoader = () => {
    useEffect(() => {
        const script = document.createElement('script');
        
        // Determine which site key to use based on the environment
        const siteKey = process.env.NODE_ENV === 'production' ? 
            process.env.REACT_APP_RECAPTCHA_SITE_KEY_PRODUCTION : 
            process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEVELOPMENT;

        // Use the determined site key to set the source of the reCAPTCHA script
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        // Clean up the script when component unmounts
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
};

export default DynamicScriptLoader;
