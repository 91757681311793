import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
console.log('Start of ResendConfirmationPage');

const ResendConfirmation = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const msg = params.get('message');
    const token = params.get('token');
    if (msg) {
      setMessage(msg);
    }

    if (token) {
      const resendConfirmation = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/resend_confirmation?token=${token}`);
          const data = await response.json();
          if (data.status === 'success') {
            setMessage('A new confirmation email has been sent to your email address.');
            setTimeout(() => {
              navigate('/');
            }, 3000); // Redirect to home after 3 seconds
          } else {
            setMessage(data.message || 'Failed to resend confirmation email.');
          }
        } catch (error) {
          console.error('Error resending confirmation email:', error);
          setMessage('An error occurred. Please try again.');
        }
      };

      resendConfirmation();
    }
  }, [location, navigate]);

  return (
    <div>
      <h2>Resend Confirmation Email</h2>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResendConfirmation;
