// AboutPage.js
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import '../css/PrivacyPolicyPage.css'; // Import the CSS file for custom styles
console.log('Start of AboutPage.js');

const AboutPage = () => {
  const { t } = useTranslation();
  const bannerImageFile = t('aboutPage.bannerImage');

  // Dynamically require the image so Webpack can process it
  let bannerImage;
  try {
    bannerImage = require(`../assets/images/${bannerImageFile}`);
  } catch (error) {
    console.error("Image not found:", error);
  }

  return (
    <div className="privacy-policy-page">
      <img src={bannerImage} alt="Top Banner" className="img-fluid" />
      <legend className="border-bottom mb-4">{t('aboutPage.legend')}</legend>
      <div>
        <p>{t('aboutPage.aboutText1')}</p>
        <p>
          <Trans
            i18nKey="aboutPage.aboutText2"
            components={{ bold: <strong /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="aboutPage.aboutText3"
            components={{ bold: <strong /> }}
          />
          {' '}
          <Trans
            i18nKey="aboutPage.aboutText4"
            components={{ bold: <strong /> }}
          />
        </p>
        <p>{t('aboutPage.aboutText5')}</p>
        <p>
          <Trans
            i18nKey="aboutPage.aboutText6"
            components={{ bold: <strong /> }}
          />
          {t('aboutPage.aboutText7')}
        </p>
      </div>
    </div>
  );
};
export default AboutPage;