import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    fetch('/admin/logout')
      .then(() => {
        navigate('/admin/login');
      });
  };

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <button onClick={handleLogout}>Logout</button>
      {/* Add links to manage announcements */}
    </div>
  );
};

export default AdminDashboard;
