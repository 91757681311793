import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend) // Loads translation files from a backend server
  .use(LanguageDetector) // Detects the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    fallbackLng: 'en', // Fallback language if the detected language is not available
    supportedLngs: ['en', 'fr'], // List of supported languages
    defaultNS: 'translation', // Default namespace for translation files
    debug: true, // Enable debug mode for development

    // Options specific to language detection
    detection: {
      order: ["queryString", "cookie", "navigator"], // Add 'navigator' to detect the browser's language
      cache: ["cookie"], // Cache the detected language in cookies
    },

    // Options specific to handling missing keys and translation
    saveMissing: true, // Enable saving missing keys
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      console.warn(`Missing translation for key: ${key}`); // Log the missing key
      return fallbackValue || `Translation not available`; // Return a default message
    },

    // Interpolation settings
    interpolation: {
      escapeValue: false, // React already handles XSS protection
    },

    // Backend settings for loading translation files
    backend: {
      loadPath: '/static/locales/{{lng}}/translation.json', // Path to translation files
    },

    // React-specific options
    react: {
      useSuspense: true // Use suspense for loading translations
    },
  });

export default i18n;