// FlashMessage.js
import React from 'react';
import PropTypes from 'prop-types';

const FlashMessage = ({ message, type }) => {
  if (!message) return null;

  let className = 'alert ';
  switch (type) {
    case 'success':
      className += 'alert-success';
      break;
    case 'error':
      className += 'alert-danger';
      break;
    case 'warning':
      className += 'alert-warning';
      break;
    case 'info':
      className += 'alert-info';
      break;
    default:
      className += 'alert-secondary';
  }

  return (
    <div className={className} role="alert">
      {message}
    </div>
  );
};

FlashMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default FlashMessage;
